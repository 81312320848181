import React from "react";
import Header from "../components/header";

import {Helmet} from "react-helmet";
import "../styles/index.css";
import {StaticImage} from "gatsby-plugin-image";

function Index() {
    return (
        <main>
            <Helmet>
                <title>Case Study Template</title>
            </Helmet>

            <Header/>

            <section class="bg-ixdf">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-6 order-2 order-lg-1">
                            <h1 class="text-light">Case Study Template</h1>

                            <p class="lead text-light">
                                The one-sentence description of the case goes here. </p>
                        </div>
                        <div class="col-lg-6 order-1 order-lg-2">

                            <StaticImage
                                src="../images/card-thumb-matching-system.png"
                                alt="Indiana University logo"
                                placeholder="blurred"
                                className="img-fluid"
                            />

                        </div>
                    </div>
                </div>
            </section>

            {/* <!-- content --> */}
            <section>
                <div className="container">
                    <div className="row">
                        <h2>Situation</h2>
                        <p >
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
                            labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco
                            laboris nisi ut aliquip ex ea commodo consequat.
                        </p>
                    </div>
                </div>
            </section>

            <section className="bg-light">
                <div className="container">
                    <div className="row">
                        <h2>Task</h2>
                        <p className="lead">
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
                            labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco
                            laboris nisi ut aliquip ex ea commodo consequat.
                        </p>
                    </div>
                </div>
            </section>

            <section>
                <div className="container">

                    <div className="row">

                        <div className="col-lg-6 order-2 order-lg-1">
                            <div id="prototypeCarousel" className="carousel slide" data-ride="carousel"
                                 data-interval="false">
                                <ol className="carousel-indicators my-4">
                                    <li data-target="#prototypeCarousel" data-slide-to="0" className="active"></li>
                                    <li data-target="#prototypeCarousel" data-slide-to="1"></li>
                                    <li data-target="#prototypeCarousel" data-slide-to="2"></li>
                                    <li data-target="#prototypeCarousel" data-slide-to="3"></li>
                                    <li data-target="#prototypeCarousel" data-slide-to="4"></li>
                                    <li data-target="#prototypeCarousel" data-slide-to="5"></li>
                                </ol>
                                <div className="carousel-inner">
                                    <div className="carousel-item active">
                                        <StaticImage
                                            src="../images/vete-how-it-works.png"
                                            alt="..."
                                            placeholder="blurred"
                                            className="img-fluid"
                                        />
                                        <div className="carousel-caption mb-4">
                                            <p>Early prototype of the introduction screen</p>
                                        </div>
                                    </div>
                                    <div className="carousel-item">
                                        <StaticImage
                                            src="../images/vete-intro-video.png"
                                            alt="..."
                                            placeholder="blurred"
                                            className="img-fluid"
                                        />
                                        <div className="carousel-caption mb-4">
                                            <p>Early prototype of the introduction video</p>
                                        </div>
                                    </div>

                                    <div className="carousel-item">
                                        <a href="#admin-ux-drawing">
                                            <StaticImage
                                                src="../images/vete-test-three-appliances.png"
                                                alt="..."
                                                placeholder="blurred"
                                                className="img-fluid"
                                            />
                                        </a>
                                        <div className="carousel-caption mb-4">
                                            <p>Early prototype of system a pre-test concept</p>
                                        </div>
                                    </div>

                                    <div className="carousel-item">
                                        <a href="#admin-ux-drawing">
                                            <StaticImage
                                                src="../images/vete-mega-test.png"
                                                alt="..."
                                                placeholder="blurred"
                                                className="img-fluid"
                                            />
                                        </a>
                                        <div className="carousel-caption mb-4">
                                            <p>Early prototype of a different pre-test concept</p>
                                        </div>
                                    </div>

                                    <div className="carousel-item">
                                        <a href="#admin-ux-drawing">
                                            <StaticImage
                                                src="../images/vete-mega-test-results.png"
                                                alt="..."
                                                placeholder="blurred"
                                                className="img-fluid"
                                            />
                                            {/*<img src="/static/vete-mega-test-results.png" className="d-block w-100" alt="..."></img>*/}
                                        </a>
                                        <div className="carousel-caption mb-4">
                                            <p>Early prototype of system for showing the user's score</p>
                                        </div>
                                    </div>

                                    <div className="carousel-item">
                                        <a href="#admin-ux-drawing">
                                            <StaticImage
                                                src="../images/vete-retest.png"
                                                alt="Journey mapping course on an iPad"
                                                placeholder="blurred"
                                                className="img-fluid"
                                            />
                                        </a>
                                        <div className="carousel-caption mb-4">
                                            <p>Early prototype allowing user to re-take the test</p>
                                        </div>
                                    </div>

                                </div>

                                {/* carcousel controls */}
                                <a className="carousel-control-prev" href="#prototypeCarousel" role="button"
                                   data-slide="prev">
                                    <span className="carousel-control-prev-icon" aria-hidden="true">.</span>
                                    <span className="sr-only">Previous</span>
                                </a>
                                <a className="carousel-control-next" href="#prototypeCarousel" role="button"
                                   data-slide="next">
                                    <span className="carousel-control-next-icon" aria-hidden="true">.</span>
                                    <span className="sr-only">Next</span>
                                </a>
                            </div>
                        </div>

                        <div className="col-lg-6 order-2 order-lg-1">

                            <p className="">
                                Over the course of testing, we found and addressed a number of
                                small UX issues. For example, we found that many users were
                                confused by the language to toggle between “Search for Rider”
                                and “Search for Driver." By switching from words to icons, the
                                usability was (surprisingly) improved.
                            </p>

                            <p className="">
                                In early testing, we also found that users were not discovering
                                and chooseing alternative commute modes. To remedy this, we
                                added more available options to the first screen of the
                                interface.{" "}
                            </p>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container">

                    <h2>Insights</h2>
                    <p className="lead">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
                        labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco
                        laboris nisi ut aliquip ex ea commodo consequat.
                    </p>
                    <div className="row">

                        <div className="col-lg-6 order-2 order-lg-1">
                            <div id="prototypeCarousel" className="carousel slide" data-ride="carousel"
                                 data-interval="false">
                                <ol className="carousel-indicators my-4">
                                    <li data-target="#prototypeCarousel" data-slide-to="0" className="active"></li>
                                    <li data-target="#prototypeCarousel" data-slide-to="1"></li>
                                    <li data-target="#prototypeCarousel" data-slide-to="2"></li>
                                    <li data-target="#prototypeCarousel" data-slide-to="3"></li>
                                    <li data-target="#prototypeCarousel" data-slide-to="4"></li>
                                    <li data-target="#prototypeCarousel" data-slide-to="5"></li>
                                </ol>
                                <div className="carousel-inner">
                                    <div className="carousel-item active">
                                        <StaticImage
                                            src="../images/vete-how-it-works.png"
                                            alt="..."
                                            placeholder="blurred"
                                            className="img-fluid"
                                        />
                                        <div className="carousel-caption mb-4">
                                            <p>Early prototype of the introduction screen</p>
                                        </div>
                                    </div>
                                    <div className="carousel-item">
                                        <StaticImage
                                            src="../images/vete-intro-video.png"
                                            alt="..."
                                            placeholder="blurred"
                                            className="img-fluid"
                                        />
                                        <div className="carousel-caption mb-4">
                                            <p>Early prototype of the introduction video</p>
                                        </div>
                                    </div>

                                    <div className="carousel-item">
                                        <a href="#admin-ux-drawing">
                                            <StaticImage
                                                src="../images/vete-test-three-appliances.png"
                                                alt="..."
                                                placeholder="blurred"
                                                className="img-fluid"
                                            />
                                        </a>
                                        <div className="carousel-caption mb-4">
                                            <p>Early prototype of system a pre-test concept</p>
                                        </div>
                                    </div>

                                    <div className="carousel-item">
                                        <a href="#admin-ux-drawing">
                                            <StaticImage
                                                src="../images/vete-mega-test.png"
                                                alt="..."
                                                placeholder="blurred"
                                                className="img-fluid"
                                            />
                                        </a>
                                        <div className="carousel-caption mb-4">
                                            <p>Early prototype of a different pre-test concept</p>
                                        </div>
                                    </div>

                                    <div className="carousel-item">
                                        <a href="#admin-ux-drawing">
                                            <StaticImage
                                                src="../images/vete-mega-test-results.png"
                                                alt="..."
                                                placeholder="blurred"
                                                className="img-fluid"
                                            />
                                            {/*<img src="/static/vete-mega-test-results.png" className="d-block w-100" alt="..."></img>*/}
                                        </a>
                                        <div className="carousel-caption mb-4">
                                            <p>Early prototype of system for showing the user's score</p>
                                        </div>
                                    </div>

                                    <div className="carousel-item">
                                        <a href="#admin-ux-drawing">
                                            <StaticImage
                                                src="../images/vete-retest.png"
                                                alt="Journey mapping course on an iPad"
                                                placeholder="blurred"
                                                className="img-fluid"
                                            />
                                        </a>
                                        <div className="carousel-caption mb-4">
                                            <p>Early prototype allowing user to re-take the test</p>
                                        </div>
                                    </div>

                                </div>

                                {/* carcousel controls */}
                                <a className="carousel-control-prev" href="#prototypeCarousel" role="button"
                                   data-slide="prev">
                                    <span className="carousel-control-prev-icon" aria-hidden="true">.</span>
                                    <span className="sr-only">Previous</span>
                                </a>
                                <a className="carousel-control-next" href="#prototypeCarousel" role="button"
                                   data-slide="next">
                                    <span className="carousel-control-next-icon" aria-hidden="true">.</span>
                                    <span className="sr-only">Next</span>
                                </a>
                            </div>
                        </div>

                        <div className="col-lg-6 order-2 order-lg-1">

                            <p className="">
                                Over the course of testing, we found and addressed a number of
                                small UX issues. For example, we found that many users were
                                confused by the language to toggle between “Search for Rider”
                                and “Search for Driver." By switching from words to icons, the
                                usability was (surprisingly) improved.
                            </p>

                            <p className="">
                                In early testing, we also found that users were not discovering
                                and chooseing alternative commute modes. To remedy this, we
                                added more available options to the first screen of the
                                interface.{" "}
                            </p>
                        </div>
                    </div>
                </div>
            </section>

            {/* <!-- end content --> */}
        </main>
    );
}

export default Index;
